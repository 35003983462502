import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Blog from './components/Blog';
import Events from './components/Events';
import Contact from './components/Contact';
import GalleryDetail from './components/GalleryDetail';
import Footer from './components/Footer';
import './App.css';
import EventDetail from './components/EventDetail';
import BlogDetail from './components/BlogDetail';
import Team from './components/Team';
const App = () => {
    return (
        <Router>
            <div className="app">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/gallery/:eventName" element={<GalleryDetail />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:postTitle" element={<BlogDetail />} />
                    <Route path="/events" element={<Events />} />
                    <Route path="/events/:eventName" element={<EventDetail />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/team" element={<Team />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
