import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 Asociația de tineret "ELF". All rights reserved.</p>
            <div className="footer-social">
                <a href="https://www.facebook.com/profile.php?id=61564211523393">Facebook</a>
                <a href="https://www.instagram.com/elf_oradea/">Instagram</a>
            </div>
        </footer>
    );
};

export default Footer;
