import React from 'react';
import EventCard from './EventCard';
import './Events.css';

const Events = () => {
    const events = [
        {
            name: 'TalentsOfTomorrow',
            date: '2024-09-13',
            description: 'Un spectacol open stage vibrant și plin de energie unde orice formă de artă e binevenită! Vino să ne încânți cu cântece, dansuri, poezii, momente teatrale, stand-up, magie sau orice altă expresie artistică îți trece prin minte. Scena e a ta!',
            image: require('./assets/TalentsOfTomorrow.png')
        },
    ];

    return (
        <div className="events">
            <h1>Evenimente</h1>
            {events.map((event, index) => (
                <EventCard key={index} event={event} />
            ))}
        </div>
    );
};

export default Events;
