import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './GalleryDetail.css';

// Import images for use in the gallery detail
import img1 from './assets/img1.jpg'; // Adjust the file path if needed
import img2 from './assets/img2.jpg'; // Adjust the file path if needed
import img3 from './assets/img3.jpg'; // Adjust the file path if needed
import img4 from './assets/img4.jpg'; // Adjust the file path if needed

const GalleryDetail = () => {
    const [isViewingImage, setIsViewingImage] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const { eventName } = useParams();

    // Define the events with their images
    const events = [
        { 
            name: 'Event 1', 
            images: [img1, img2] 
        },
        { 
            name: 'Event 2', 
            images: [img3, img4] 
        },
        // Add more events here
    ];

    // Find the event based on the eventName parameter
    const event = events.find(event => event.name === eventName);

    // Early return if event is not found
    if (!event) {
        return <p>Event not found</p>;
    }

    // Function to move to the next image
    const handleNext = () => {
        setCurrentImageIndex(prevIndex => 
            (prevIndex + 1) % event.images.length
        );
    };

    // Function to move to the previous image
    const handlePrevious = () => {
        setCurrentImageIndex(prevIndex => 
            (prevIndex - 1 + event.images.length) % event.images.length
        );
    };

    // Function to show the full gallery view
    const handleGalleryView = () => {
        setIsViewingImage(false);
    };

    // Function to show the individual image view
    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setIsViewingImage(true);
    };

    return (
        <div className="gallery-detail">
            <h1>{event.name}</h1>
            {!isViewingImage ? (
                <div className="gallery-thumbnails">
                    {event.images.map((image, index) => (
                        <img 
                            key={index} 
                            src={image} 
                            alt={`${event.name} ${index}`} 
                            className="thumbnail" 
                            onClick={() => handleImageClick(index)}
                        />
                    ))}
                </div>
            ) : (
                <div className="gallery-image-container">
                    <button className="nav-button" onClick={handlePrevious}>❮</button>
                    <img 
                        src={event.images[currentImageIndex]} 
                        alt={`${event.name} ${currentImageIndex}`} 
                        className="gallery-image"
                    />
                    <button className="nav-button" onClick={handleNext}>❯</button>
                    <button className="back-button" onClick={handleGalleryView}>Back to Gallery</button>
                </div>
            )}
        </div>
    );
};

export default GalleryDetail;
