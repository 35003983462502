// src/components/BlogPost.js
import React from 'react';
import { Link } from 'react-router-dom';
import './BlogPost.css';

// Import thumbnail images for blog posts
import blog1 from './assets/blog1.jpg'; // Update path if necessary
import blog2 from './assets/blog2.jpg'; // Update path if necessary

const BlogPost = ({ post }) => {
    // Map post titles to thumbnail images
    const thumbnails = {
        'Post 1': blog1,
        'Post 2': blog2,
        // Add more mappings as needed
    };

    const thumbnail = thumbnails[post.title] || blog1; // Default to blog1 if not found

    return (
        <div className="blog-card">
            <img src={thumbnail} alt={post.title} className="blog-card-thumbnail" />
            <h2>{post.title}</h2>
            <p>{post.content.slice(0, 100)}...</p> {/* Show a preview of the content */}
            <Link to={`/blog/${post.title}`} className="blog-card-link">Read More</Link>
        </div>
    );
};

export default BlogPost;
