import React from 'react';
import { Link } from 'react-router-dom';
import './Gallery.css';
import thumb1 from './assets/thumb1.jpg';
import thumb2 from './assets/thumb2.jpg';
const Gallery = () => {
    const events = [
        { 
            name: 'Event 1', 
            images: ['img1.jpg', 'img2.jpg'], 
            thumbnail: thumb1 
        },
        { 
            name: 'Event 2', 
            images: ['img3.jpg', 'img4.jpg'], 
            thumbnail: thumb2
        },
        // Add more events here
    ];

    return (
        <div className="gallery">
            <h1>Galerie</h1>
            <div className="gallery-folders">
                {events.map((event, index) => (
                    <Link 
                        to={`/gallery/${event.name}`} 
                        key={index} 
                        className="gallery-folder-link"
                    >
                        <div className="gallery-folder">
                            <img src={event.thumbnail} alt={`${event.name} thumbnail`} className="folder-thumbnail"/>
                            <div className="folder-info">
                                <h2>{event.name}</h2>
                                <p>View Photos</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
