// src/components/BlogDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import './BlogDetail.css';

// Import thumbnail images for blog posts
import blog1 from './assets/blog1.jpg'; // Update path if necessary
import blog2 from './assets/blog2.jpg'; // Update path if necessary

const BlogDetail = () => {
    const { postTitle } = useParams();

    // Define blog posts with their content and thumbnails
    const posts = [
        { 
            title: 'Post 1', 
            content: 'This is the first blog post. It provides detailed information about the topic discussed in this post.',
            thumbnail: blog1
        },
        { 
            title: 'Post 2', 
            content: 'This is the second blog post. It covers another topic in depth, providing valuable insights and information.',
            thumbnail: blog2
        },
        // Add more posts here
    ];

    // Find the post based on the postTitle parameter
    const post = posts.find(post => post.title === postTitle);

    if (!post) {
        return <p>Post not found</p>;
    }

    return (
        <div className="blog-detail">
            <h1>{post.title}</h1>
            <img src={post.thumbnail} alt={post.title} className="blog-detail-thumbnail"/>
            <p>{post.content}</p>
        </div>
    );
};

export default BlogDetail;
