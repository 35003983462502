import React, { useState } from 'react';
import './Team.css'; // Ensure the CSS styles are updated accordingly
import aliceImage from './assets/AlicePopa.png'; // Replace with correct image paths
import georgianaImage from './assets/GeorgiSabau.png'; // Replace with correct image paths
import biancaImage from './assets/BiaNistor.png'; // Replace with correct image paths
import claraImage from './assets/ClaraLeu.png'; // Replace with correct image paths
import cristinaImage from './assets/CristinaLeu.png'; // Replace with correct image paths
import romanianFlag from './assets/romanian-flag.png';
import americanFlag from './assets/american-flag.png';

const Team = () => {
    const [isRomanian, setIsRomanian] = useState(true);

    const toggleLanguage = () => {
        setIsRomanian(!isRomanian);
    };

    return (
        <div className="home">
            <div className={`home-content ${isRomanian ? 'turn-romanian' : 'turn-english'}`}>
                <img
                    src={isRomanian ? americanFlag : romanianFlag}
                    alt={isRomanian ? 'Romanian Flag' : 'American Flag'}
                    className="flag-toggle"
                    onClick={toggleLanguage}
                />

                {/* Romanian Version */}
                {isRomanian ? (
                    <div className="column">
                        {/* ELF content */}
                        <h1>✨ Asociația de tineret "ELF" ✨</h1>
                        <h2>🌟 Experience Life to the Fullest 🌟</h2>
                        {/* Other content */}

                        <div className="row">
                        {/* Alice Popa Profile Card */}
                        <div className="profile-card">
                            <img src={aliceImage} alt="Alice Popa" className="profile-image" />
                            <h2>Alice Popa</h2>
                            <h3>she/her</h3>
                            <p>Alice Popa este profesoara de română care sparge tiparele! Pe lângă pasiunea pentru literatură, Alice își pune inima în voluntariat și proiecte civice, dovedind că un profesor poate fi și un supererou al comunității.</p>
                            <p>Cu un vibe autentic și un spirit mereu pus pe fapte bune, Alice aduce oamenii împreună pentru a crea schimbări reale în comunitate.</p>
                        </div>

                        {/* Georgiana Sabău Profile Card */}
                        <div className="profile-card">
                            <img src={georgianaImage} alt="Georgiana Sabău" className="profile-image" />
                            <h2>Georgiana Sabău</h2>
                            <h3>she/her</h3>
                            <p>Georgiana Sabău, învățătoarea cu o energie molipsitoare și inițiativă de neoprit! De 13 ani, se dedică ONG-urilor, aducând un suflu nou în fiecare proiect și transformând ideile în realitate.</p>
                            <p>Cu Georgiana în echipă, entuziasmul e garantat, iar comunitatea devine un loc mai bun pentru toți.</p>
                        </div>

                        {/* Bianca Nistor Profile Card */}
                        <div className="profile-card">
                            <img src={biancaImage} alt="Bianca Nistor" className="profile-image" />
                            <h2>Bianca Nistor</h2>
                            <h3>she/her</h3>
                            <p>Bianca Nistor, studentă la UBB la Informatică, este definiția echilibrului între rațiune și creativitate. Cu o experiență de 7 ani în ONG-uri, Bianca își folosește abilitățile pentru a se implica cât mai mult în comunitate.</p>
                            <p>Persuasivă și plină de idei, ea se ocupă de imaginea echipei, de la postări până la afișe, aducând mereu un strop de originalitate în tot ceea ce face.</p>
                        </div>
                        </div>

                        <div className="row">
                        {/* Clara Leu Profile Card */}
                        <div className="profile-card">
                            <img src={claraImage} alt="Bianca Nistor" className="profile-image" />
                            <h2>Clara Leu</h2>
                            <h3>she/her</h3>
                            <p>Clara Leu, studentă la UBB la Istorie, este o sursă inepuizabilă de idei și creativitate. Cu 5 ani de experiență în ONG-uri, Clara se ocupă de scrierea proiectelor și de research-ul esențial, fiind mereu la curent cu cele mai recente informații</p>
                            <p>Spontană și plină de entuziasm, Clara aduce un strop de haos plăcut în echipă, transformând provocările în oportunități și proiectele în succes.</p>
                        </div>

                        {/* Cristina Leu Profile Card */}
                        <div className="profile-card">
                            <img src={cristinaImage} alt="Bianca Nistor" className="profile-image" />
                            <h2>Cristina Leu</h2>
                            <h3>she/her</h3>
                            <p>Cristina Leu, consultantul fiscal și contabil care aduce ordine în haos cu un zâmbet! Rațională și empatică, Cristina combină un simț practic impecabil cu o capacitate rară de a înțelege nevoile fiecăruia</p>
                            <p>Cu ea la bord, toată lumea e pusă la punct și totul merge strună. În echipa noastră, Cristina este stâlpul de încredere care face lucrurile să se întâmple!</p>
                        </div>
                        </div>
                    </div>
                ) : (
                    /* English Version */
                    <div className="column">
                        {/* ELF content */}
                        <h1>✨ ELF Youth Association ✨</h1>
                        <h2>🌟 Experience Life to the Fullest 🌟</h2>
                        {/* Other content */}

                        {/* Alice Popa Profile Card */}
                        <div className="profile-card">
                            <img src={aliceImage} alt="Alice Popa" className="profile-image" />
                            <h2>Alice Popa</h2>
                            <h3>she/her</h3>
                            <p>Alice Popa is a Romanian teacher who breaks the mold! Beyond her passion for literature, Alice puts her heart into volunteering and civic projects, proving that a teacher can also be a superhero of the community.</p>
                            <p>With an authentic vibe and a spirit always driven by good deeds, Alice brings people together to create real change in the community.</p>
                        </div>

                        {/* Georgiana Sabău Profile Card */}
                        <div className="profile-card">
                            <img src={georgianaImage} alt="Georgiana Sabău" className="profile-image" />
                            <h2>Georgiana Sabău</h2>
                            <h3>she/her</h3>
                            <p>Georgiana Sabău, the teacher with infectious energy and unstoppable initiative! For 13 years, she has been dedicated to NGOs, bringing fresh ideas to every project and transforming them into reality.</p>
                            <p>With Georgiana on the team, enthusiasm is guaranteed, and the community becomes a better place for everyone.</p>
                        </div>

                        {/* Bianca Nistor Profile Card */}
                        <div className="profile-card">
                            <img src={biancaImage} alt="Bianca Nistor" className="profile-image" />
                            <h2>Bianca Nistor</h2>
                            <h3>she/her</h3>
                            <p>Bianca Nistor, a student at UBB in Computer Science, represents the perfect balance between reason and creativity. With 7 years of NGO experience, Bianca uses her skills to get as involved as possible in the community.</p>
                            <p>Persuasive and full of ideas, she manages the team’s image, from posts to banners, always adding a touch of originality to everything she does.</p>
                        </div>

                        {/* Clara Leu Profile Card */}
                        <div className="profile-card">
                            <img src={claraImage} alt="Clara Leu" className="profile-image" />
                            <h2>Clara Leu</h2>
                            <h3>she/her</h3>
                            <p>Clara Leu, a student at UBB majoring in History, is an endless source of ideas and creativity. With 5 years of experience in NGOs, Clara handles project writing and essential research, always staying up-to-date with the latest information.</p>
                            <p>Spontaneous and full of enthusiasm, Clara brings a pleasant touch of chaos to the team, turning challenges into opportunities and projects into success.</p>
                        </div>

                        {/* Cristina Leu Profile Card */}
                        <div className="profile-card">
                            <img src={cristinaImage} alt="Cristina Leu" className="profile-image" />
                            <h2>Cristina Leu</h2>
                            <h3>she/her</h3>
                            <p>Cristina Leu, the tax and accounting consultant who brings order to chaos with a smile! Rational and empathetic, Cristina combines an impeccable practical sense with a rare ability to understand everyone's needs.</p>
                            <p>With her on board, everyone is on track, and everything runs smoothly. In our team, Cristina is the trusted pillar that makes things happen!</p>
                        </div>

                    </div>
                )}
            </div>
        </div>
    );
};

export default Team;
