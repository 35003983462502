// src/components/EventDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import './EventDetail.css';
import { Link } from 'react-router-dom';

const EventDetail = () => {
    const { eventName } = useParams();

    // Define the events with their details
    const events = [
        { 
            name: 'TalentsOfTomorrow', 
            date: '13 Septembrie 2024', 
            hour: '17:30',
            location: 'Va fi transmisă după completarea formularului.',
            description: `
                <h2>🌟 <strong>Talents of Tomorrow</strong> 🌟 🎤</h2>
                <h3>Ce?</h3>
                <p>Un spectacol open mic vibrant și plin de energie unde orice formă de artă e binevenită! Vino să ne încânți cu cântece, dansuri, poezii, momente teatrale, stand-up, magie sau orice altă expresie artistică îți trece prin minte. Scena e a ta!</p>
                <h3>Cine?</h3>
                <p>Oricine dorește să își exprime talentul și să aducă un zâmbet pe fețele spectatorilor! Nu e nevoie să fii un artist super mega talentat – ne dorim doar să te simți liber și să te bucuri de momentul tău.</p>
                <h3>Când?</h3>
                <p>13 Septembrie, de la ora 17:30. Pregătește-te pentru o seară de neuitat plină de creativitate și spontaneitate!</p>
                <h3>Unde?</h3>
                <p>Locatia va fi transmisă fiecărei persoane înscrise după completarea formularului. Nu uita să te înscrii pentru a primi detaliile!</p>
                <h3>Cum?</h3>
                <p>Pentru a-ți rezerva locul pe scenă sau în public, completează formularul de mai jos</p>
                <p><strong>💥 Haide să facem împreună din această seară un moment de magie și inspirație! Ne vedem pe 13 Septembrie! 💥</strong></p>
            `,
            image: require('./assets/TalentsOfTomorrow.png'),
            signupLink: 'https://forms.gle/yfMAhWA2JKgfdLLn9'
        },
    ];

    // Find the event based on the eventName parameter
    const event = events.find(event => event.name === eventName);

    if (!event) {
        return <p>Event not found</p>;
    }

    return (
        <div className="event-detail">
            <h1>{event.name}</h1>
            <img src={event.image} alt={event.name} className="event-image"/>
            <p><strong>Data:</strong> {event.date}</p>
            <p><strong>Ora:</strong> {event.hour}</p>
            <p><strong>Locatie:</strong> {event.location}</p>
            <div dangerouslySetInnerHTML={{ __html: event.description }} />
            <a href={event.signupLink} className="signup-link">Îndrăznește să fii altfel!!</a>
            <div>
            <Link className="back-link" to="/events">Back</Link>
            </div>
        </div>
        
    );
};

export default EventDetail;
