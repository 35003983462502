// src/components/Blog.js
import React from 'react';
import BlogPost from './BlogPost';
import './Blog.css';

const Blog = () => {
    const posts = [
        { title: 'Post 1', content: 'This is the first blog post' },
        { title: 'Post 2', content: 'This is the second blog post' },
        // Add more posts here
    ];

    return (
        <div className="blog">
            <h1>Blog</h1>
            {posts.map((post, index) => (
                <BlogPost key={index} post={post} />
            ))}
        </div>
    );
};

export default Blog;
