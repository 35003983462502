import React from 'react';
import ContactForm from './ContactForm';
import './Contact.css';
import logo from './assets/Logo.svg';
import elImg from './assets/ELF.png'; // Ensure the path is correct

const Contact = () => {
    return (
        <div className="contact-container"> {/* This is the new container for the gradient background */}
            <div className="contact"> {/* The contact card that will remain white */}
                <img src={logo} alt="Asociația de tineret ELF" className="home-image" />
                <h1>Contact</h1>
                <p>Dacă ai întrebări sau dorești să ne contactezi, completează formularul de mai jos:</p>
                <p>Sau contacteaza-ne la:</p>
                <p>asociatiaelforadea@gmail.com</p>
                <ContactForm />
               
                <img src={elImg} alt="Asociația de tineret ELF" className="home-image" />
            </div>
        </div>
    );
};

export default Contact;
