// Home.js
import React, { useState } from 'react';
import './Home.css';
import elImg from './assets/ELF.png'; // Ensure the path is correct
import romanianFlag from './assets/romanian-flag.png'; // Add the Romanian flag image
import americanFlag from './assets/american-flag.png'; // Add the American flag image

const Home = () => {
    const [isRomanian, setIsRomanian] = useState(true); // State to toggle between Romanian and English

    const toggleLanguage = () => {
        setIsRomanian(!isRomanian); // Toggle between the two languages
    };

    return (
        <div className="home">
            <div className={`home-content ${isRomanian ? 'turn-romanian' : 'turn-english'}`}>
                
                {/* Toggle Button */}
                <img
                    src={isRomanian ?  americanFlag : romanianFlag}
                    alt={isRomanian ? 'Romanian Flag' : 'American Flag'}
                    className="flag-toggle"
                    onClick={toggleLanguage}
                />
                
                {/* Romanian Version */}
                {isRomanian ? (
                    <div className="column">
                        <h1>✨ Asociația de tineret "ELF" ✨</h1>
                        <h2>🌟 Experience Life to the Fullest 🌟</h2>
                        <p>Suntem o organizație non-guvernamentală dedicată tinerilor, creând un mediu în care fiecare dintre voi poate să-și dezvolte potențialul și să devină cea mai bună versiune a sa! 🌱</p>
                        <p><strong>📚 Educație nonformală:</strong> Învățarea se petrece peste tot, nu doar în sălile de clasă. Prin activități creative și interactive, oferim noi perspective și abilități esențiale de viață.</p>
                        <p><strong>🌱 Îmbogățirea comunității:</strong> Suntem aici pentru a face o diferență în comunitatea noastră, aducând oamenii împreună prin proiecte și inițiative care construiesc conexiuni puternice.</p>
                        <p><strong>🌍 Interculturalitate:</strong> Promovăm diversitatea și dialogul intercultural deoarece înțelegerea și respectarea diferitelor perspective sunt cheia unei lumi mai bune.</p>
                        
                        <h3>Despre Noi</h3>
                        <p>Vrem să fim un spațiu deschis pentru toți tinerii care doresc să învețe, să crească și să contribuie la o lume mai bună.</p>
                        <p>Încurajăm spiritul civic prin voluntariat și educația în spirit european, pentru ca împreună să construim un viitor mai luminos.</p>
                        <p>Te invităm să ne urmărești și să te implici! Îndrăznește să fii altfel!</p>
                        
                        <img src={elImg} alt="Asociația de tineret ELF" className="home-image" />
                    </div>
                ) : (
                    /* English Version */
                    <div className="column">
                        <h1>✨ ELF Youth Association ✨</h1>
                        <h2>🌟 Experience Life to the Fullest 🌟</h2>
                        <p>We are a youth-focused NGO dedicated to creating an environment where each of you can develop your potential and become the best version of yourself! 🌱</p>
                        <p><strong>📚 Non-formal Education:</strong> Learning happens everywhere, not just in classrooms. Through creative and interactive activities, we offer new perspectives and essential life skills.</p>
                        <p><strong>🌱 Community Enrichment:</strong> We're here to make a difference in our community, bringing people together through projects and initiatives that build strong connections.</p>
                        <p><strong>🌍 Interculturality:</strong> We promote diversity and intercultural dialogue because understanding and respecting different perspectives is key to a better world.</p>
                        
                        <h3>About Us</h3>
                        <p>We aim to be an open space for all young people who want to learn, grow, and contribute to a better world.</p>
                        <p>We encourage civic engagement through volunteering and European spirit education, so that together we can build a brighter future.</p>
                        <p>Follow us and get involved! Dare to be different!</p>
                        
                        <img src={elImg} alt="ELF Youth Association" className="home-image" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Home;
